import * as React from 'react'
import loadable from '@loadable/component'
// import { Link } from "gatsby"
import './styles.scss'

const MenuDropdown = loadable(() => import('../MenuDropdown'))

const Menu = ({ data, isExpanded }) => {
    //document.body.classList.toggle('has-overlay', isExpanded) // Toggle class on body which sets overflow to hidden

    return (
        <nav className={`c-header-nav ${isExpanded ? 'is-open' : 'is-closed'}`}>
            <ul className="c-header-menu">
                {data.map((menuItem, index) => {
                    // Check to see if the item has any children
                    const children = menuItem.childItems.nodes.length
                        ? menuItem.childItems.nodes
                        : null

                    return children ? (
                        <MenuDropdown
                            key={index}
                            data={menuItem}
                            columns={
                                menuItem.childItems.nodes.length > 4 ? 2 : 1
                            }
                        />
                    ) : (
                        // If no children are present, just show the parent item
                        <li key={index} className="c-header-menu__item">
                            <a
                                className="c-header-menu__item-link"
                                href={menuItem.url}
                            >
                                {menuItem.label}
                            </a>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}

export default Menu
